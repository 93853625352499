import React, { useEffect, useState, ChangeEvent, useContext } from 'react';
import Modal from 'components/ui/modal/Modal';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import { useTranslation } from 'react-i18next';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import Button from 'components/ui/button/Button';
import useFrameworks from 'customHooks/api/useFrameworksPlans';
import LoaderStandard from 'components/ui/loaders/loaderStandard/LoaderStandard';
import Select from 'components/ui/formComponents/select/Select';
import WarningLabel from 'components/ui/statusLabels/warningLabel/WarningLabel';
import { FrameworkPlan } from 'services/api/frameworks';
import { UserContext } from 'context/userContext';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import {
  FiscalPeriodKeys,
  fiscalPeriodToSelectOption,
  fiscalPeriodTranslationMapper,
  selectOptionToFiscalPeriod
} from 'utils/fiscalYeari18nHandler';
import { updateOrganizationFiscalData } from 'actions/auth';
import { useDispatch } from 'react-redux';
import apiFetch from 'utils/apiFetch';
import useSaveFrameworksPlans from 'customHooks/api/useSaveFrameworksPlans';
import useFrameworksPlans from 'customHooks/api/useFrameworksPlans';
import padZero from 'utils/padZero';
import { createReportingPeriod, updateReportingPeriod } from 'services/api/reportingPeriod';

type FrameworksCustomizeYearModalProps = {
  saveFrameworksHandler: () => void;
  modalOpen: boolean;
  title: string;
  frameworkId: string;
};

const FrameworksCustomizeYearModal = ({
  saveFrameworksHandler,
  modalOpen,
  title,
  frameworkId
}: FrameworksCustomizeYearModalProps) => {
  const { t } = useTranslation('translation');
  const { frameworkPlans, frameworksLoading } = useFrameworksPlans();
  const [mutableFrameworkPlans, setMutableFrameworkPlans] = useState<FrameworkPlan[] | []>([]);
  const { saveFrameworkPlans } = useSaveFrameworksPlans();

  const getFrameworkPlanByName = (
    name: string | null | undefined,
    plans: FrameworkPlan[] | null | undefined
  ): FrameworkPlan | null => {
    if (!plans || !Array.isArray(plans) || !name) {
      return null; // Return null if plans is invalid or name is null/undefined
    }

    return (
      plans.find(
        (plan) => plan?.framework && plan.framework.name.toLowerCase() === name.toLowerCase()
      ) || null
    );
  };
  const [reportingYearType, setReportingYearType] = useState<string>('naturalYear'); // Default to natural year
  const [baseYear, setBaseYear] = useState<string>(new Date().getFullYear().toString());
  const selectedOrganization = useSelectedOrganization();
  const [fiscalYear, setFiscalYear] = useState(
    selectedOrganization?.reporting_period || {
      name: 'fiscal_year',
      start_day: 0,
      start_month: 0,
      end_day: 0,
      end_month: 0
    }
  );
  const [fiscalYearChanged, setFiscalYearChanged] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const getPlanDates = (): { plan_start_date: string; plan_end_date: string } => {
    const currentYear = parseInt(baseYear, 10);

    if (reportingYearType === 'naturalYear') {
      return {
        plan_start_date: `${currentYear}-01-01`,
        plan_end_date: `${currentYear}-12-31`
      };
    }

    if (reportingYearType === 'fiscalYear' && fiscalYear) {
      return {
        plan_start_date: `${currentYear - 1}-${padZero(fiscalYear.start_month)}-${padZero(
          fiscalYear.start_day
        )}`,
        plan_end_date: `${currentYear}-${padZero(fiscalYear.end_month)}-${padZero(
          fiscalYear.end_day
        )}`
      };
    }

    // Fallback
    return {
      plan_start_date: '',
      plan_end_date: ''
    };
  };
  const dispatch = useDispatch();
  const handleSave = async () => {
    setLoadingButton(true);
    // Update fiscal year if changed
    let fiscalYearRes;
    if (fiscalYearChanged && selectedOrganization?.id) {
      if (fiscalYear.id) {
        fiscalYearRes = await updateReportingPeriod(fiscalYear.id, fiscalYear);
      } else {
        fiscalYearRes = await createReportingPeriod(fiscalYear);
      }

      // Dispatch updated fiscal year to Redux store
      dispatch(updateOrganizationFiscalData(selectedOrganization.id, fiscalYearRes));
    }

    // Get plan dates based on the reporting year type
    const { plan_start_date, plan_end_date } = getPlanDates();
    // prepare the framework payload
    // Find the existing plan for the selected framework
    const existingPlan = frameworkPlans?.find((plan) => plan && plan.id === frameworkId);
    if (!existingPlan) {
      return;
    }
    const frameworkPayload: FrameworkPlan = {
      ...existingPlan,
      plan_start_date,
      plan_end_date
    };
    await saveFrameworkPlans([frameworkPayload], fiscalYearRes?.id || fiscalYear.id);
    saveFrameworksHandler();
  };

  const handleChangeReportingYearType = (value: SelectOptionFormat) => {
    setReportingYearType(value.id);
    if (value.id === 'naturalYear') {
      setFiscalYearChanged(false); // Reset fiscal year changes if switching to natural year
    }
  };

  const handleChangeBaseYear = (value: SelectOptionFormat) => {
    setBaseYear(value.id);
  };

  const handleChangeFiscalYear = (value: SelectOptionFormat) => {
    setFiscalYear({
      ...selectOptionToFiscalPeriod(value.id as FiscalPeriodKeys),
      id: fiscalYear.id || ''
    });
    setFiscalYearChanged(true);
  };
  const getYearsOptions = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 5 }, (_, i) => ({
      id: (currentYear - i).toString(),
      name: (currentYear - i).toString()
    }));
  };
  const fiscalPeriodsOptions = fiscalPeriodTranslationMapper(t);

  return (
    <Modal.WithPortal
      show={modalOpen}
      onClose={saveFrameworksHandler}
      width='780px'
      maxWidth='780px'
      closeOnOutsideClick={false}>
      <h1 className='font-24 weight-600'>{title}</h1>
      <h2 className='font-14 weight-400 mb-12'>
        {t('controlPanel.metrics.configureFrameworksCustomizeYearModal.description')}
      </h2>
      {frameworksLoading ? (
        <LoaderStandard />
      ) : (
        <FormWrapper className='gap-4'>
          <Select
            placeholder={t(
              'controlPanel.metrics.configureFrameworksCustomizeYearModal.selectOption'
            )}
            label={t(
              'controlPanel.metrics.configureFrameworksCustomizeYearModal.reportingYearType'
            )}
            value={{
              id: reportingYearType,
              name: t(
                `controlPanel.metrics.configureFrameworksCustomizeYearModal.${reportingYearType}`
              )
            }}
            options={[
              {
                id: 'naturalYear',
                name: t('controlPanel.metrics.configureFrameworksCustomizeYearModal.naturalYear')
              },
              {
                id: 'fiscalYear',
                name: t('controlPanel.metrics.configureFrameworksCustomizeYearModal.fiscalYear')
              }
            ]}
            onChangeValue={handleChangeReportingYearType}
            iconV2='signal'
          />
          <Select
            placeholder={t('controlPanel.metrics.configureFrameworksCustomizeYearModal.selectYear')}
            label={t('controlPanel.metrics.configureFrameworksCustomizeYearModal.baseYear')}
            value={{ id: baseYear, name: baseYear }}
            options={getYearsOptions()}
            onChangeValue={handleChangeBaseYear}
            iconV2='signal'
          />
          {reportingYearType === 'fiscalYear' && !fiscalYear?.id && (
            <>
              <Select
                iconV2='calendar'
                placeholder={t('profile.selectYourPeriod')}
                label={t('profile.fiscalYearStartEnd')}
                value={{
                  id: fiscalPeriodToSelectOption(fiscalYear),
                  name: t(`profile.fiscalPeriod.${fiscalPeriodToSelectOption(fiscalYear)}`)
                }}
                options={fiscalPeriodsOptions}
                onChangeValue={handleChangeFiscalYear}
                sort={false}
              />
            </>
          )}
        </FormWrapper>
      )}
      <FormButtonSection className='mt-16'>
        <Button
          lookAndFeel='primary'
          text={t('controlPanel.metrics.configureFrameworksCustomizeYearModal.save')}
          onClick={handleSave}
          loading={loadingButton}
        />
      </FormButtonSection>
    </Modal.WithPortal>
  );
};

export default FrameworksCustomizeYearModal;
